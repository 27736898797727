<!--
 * @Description: 学习时长统计
 * @Author: xiawenlong
 * @Date: 2021-04-22 19:45:20
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-04-23 11:49:14
-->
<template>
  <div class="card study chart">
    <div class="header">
      <div class="title">学习时长统计</div>
      <div class="filter">
        <ul>
          <li class="active">今日</li>
          <li>本周</li>
          <li>本月</li>
          <li>全年</li>
        </ul>
        <el-date-picker
          v-model="date"
          size="mini"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </div>
    </div>
    <div id="chartStudy" class="chart"></div>
  </div>
</template>
<script>
export default {
  name: 'StudyCard',
  data() {
    return {
      date: [],
    }
  },
}
</script>
<style lang="scss" scoped>
.card {
  &.chart {
    width: calc(100% - 383px);
    height: 307px;
    .chart {
      width: 100%;
      height: 260px;
    }
    .header {
      .filter {
        display: flex;
        align-items: center;
        ul {
          display: flex;
          li {
            cursor: pointer;
            width: 30px;
            font-size: 14px;
            font-weight: 400;
            color: #909399;
            margin-right: 20px;
            &.active {
              color: #ff7b33;
            }
          }
        }
        .el-date-editor {
          width: 250px;
        }
      }
    }
  }
}
</style>
