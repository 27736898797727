<!--
 * @Description: 快捷入口
 * @Author: xiawenlong
 * @Date: 2021-04-22 19:49:49
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-05-14 15:56:14
-->
<template>
  <div class="card enter">
    <div class="header">
      <div class="avatar">
        <img :src="userInfo.headPic" alt="" />
      </div>
      <div class="nickname">{{ userInfo.userName }}</div>
      <div
        v-if="
          (superAdminDetail.superAdmin && superAdminDetail.admin) ||
            (superAdminDetail.superAdmin && !superAdminDetail.admin)
        "
        class="identity"
      >
        超级管理员
      </div>
      <div v-if="!superAdminDetail.superAdmin && superAdminDetail.admin" class="identity">
        管理员
      </div>
      <div v-if="!superAdminDetail.superAdmin && !superAdminDetail.admin" class="identity">
        员工
      </div>
      <div class="tel">{{ userInfo.phone }}</div>
      <div class="email">{{ userInfo.email }}</div>
    </div>
    <div class="content">
      <div class="shortcut clearfix">
        <div class="title">快捷入口</div>
        <ul v-if="menu && menu.length !== 0" class="clearfix">
          <!-- <li v-for="(item, index) in quickEntrance" :key="item.path">
            <span @click="inter(item.path)">{{ item.name }}</span>
            <img
              class="close"
              src="@/assets/common/close_icon.png"
              @click="quickEntranceDel(index)"
            />
          </li> -->
          <li v-for="(item, index) in quickList" :key="item.path">
            <span @click="inter(item.path)">{{ item.title }}</span>
            <img
              class="close"
              src="@/assets/common/close_icon.png"
              @click="quickEntranceDel(item, index)"
            />
          </li>
          <li @click="goQuick">
            <i class="el-icon-plus"></i>
            添加快捷入口
          </li>
        </ul>
      </div>
      <div class="visited">
        <div class="title">最近访问</div>
        <ul class="clearfix">
          <!-- <li v-for="item in recentVisit" :key="item.path" @click="inter(item.path)">
            {{ item.name }}
          </li> -->
          <li v-for="item in list.slice(0, 8)" :key="item.path" @click="inter(item.path)">
            {{ item.title }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import to from 'await-to'
import { recentVisitList, delQuickItem, quickList } from '@/api/dashboard'
import { mapGetters } from 'vuex'
export default {
  props: {
    menu: {
      type: Array,
      default: () => [],
    },
    superAdminDetail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      clientCode: '260bb55c-47e3-48d0-bd8c-c2cf1ce5',
      recentVisit: [],
      quickEntrance: [],
      list: [],
      quickList: [],
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  created() {
    this.recentVisitListData()
    this.quickListData()
    setTimeout(() => {
      if (localStorage.getItem(localStorage.getItem('userPhone') + 'visit' + this.clientCode)) {
        this.recentVisit = JSON.parse(
          localStorage.getItem(localStorage.getItem('userPhone') + 'visit' + this.clientCode),
        )
      }
      if (
        localStorage.getItem(localStorage.getItem('userPhone') + 'quickEntrance' + this.clientCode)
      ) {
        this.quickEntrance = JSON.parse(
          localStorage.getItem(
            localStorage.getItem('userPhone') + 'quickEntrance' + this.clientCode,
          ),
        )
      }
    }, 500)
  },
  methods: {
    async recentVisitListData() {
      const [res, err] = await to(recentVisitList())
      if (err) return this.$message.warning(err.msg)
      this.list = res.data
    },
    async quickListData() {
      const [res, err] = await to(quickList())
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.quickList = res.data
    },
    goQuick() {
      this.$router.push('/dashbord/quick')
    },
    async delQuickItemData(id) {
      const [, err] = await to(delQuickItem({ id: id }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.quickListData()
    },
    quickEntranceDel(item, i) {
      this.delQuickItemData(item.id)
      this.quickEntrance.splice(i, 1)
      localStorage.setItem(
        localStorage.getItem('userPhone') + 'quickEntrance' + this.clientCode,
        JSON.stringify(this.quickEntrance),
      )
    },
    inter(path) {
      this.$router.push(path)
    },
    async helpManual() {
      let src =
        'https://statics-saas.i-ronge.com/frontend/help-book/%E8%9E%8De%E5%AD%A6%C2%B7%E6%98%8E%E5%95%86%E9%81%93%E6%93%8D%E4%BD%9C%E6%89%8B%E5%86%8C%EF%BC%88%E4%BC%81%E4%B8%9A%EF%BC%89.pdf'
      const response = await fetch(src)
      const blob = await response.blob()
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = '帮助手册'
      a.click()
      window.URL.revokeObjectURL(url)
    },
  },
}
</script>
<style lang="scss" scoped>
.card {
  &.enter {
    width: calc(100% - 383px);
    flex: 1;
    background: #ffffff;
    .header {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      .avatar {
        border-radius: 50%;
        overflow: hidden;
        width: 40px;
        height: 40px;
        margin-right: 9px;
        img {
          @extend %width-height;
        }
      }
      .nickname {
        font-size: 16px;
        color: #606266;
        margin-right: 7px;
      }
      .identity {
        width: 72px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        border: 1px solid #ff7b33;
        border-radius: 3px;
        font-size: 12px;
        color: #ff7b33;
        margin-right: 40px;
      }
      .tel {
        margin-right: 30px;
      }
    }
    .content {
      padding: 10px 0 0 12px;
      max-height: 318px;
      .title {
        font-size: 16px;
        font-weight: 400;
        color: #606266;
        margin-bottom: 10px;
        padding: 0 8px;
      }
      ul {
        li {
          position: relative;
          cursor: pointer;
          float: left;
          width: 128px;
          height: 36px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          background: #f2f2f2;
          border-radius: 4px;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          text-align: center;
          line-height: 36px;
          margin: 0 8px;
          margin-bottom: 12px;
          transition: all 0.3s;
          .close {
            position: absolute;
            right: -7px;
            top: -10px;
            width: 20px;
            height: 20px;
            opacity: 0;
            transition: all 0.3s;
          }
          &:hover {
            color: #ff7b33;
            .close {
              opacity: 1;
            }
          }
        }
      }
      .shortcut {
        li {
          &:last-of-type {
            box-sizing: border-box;
            line-height: 34px;
            border: 1px solid #c2c2c2;
            border-radius: 5px;
            color: #ff7b33;
            background-color: transparent;
          }
        }
      }
    }
  }
}
</style>
