<!--
 * @Description: 首页基础信息
 * @Author: xiawenlong
 * @Date: 2021-04-22 19:36:54
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-04-29 00:33:04
-->
<template>
  <div class="card info">
    <div class="info">
      <div class="logo">
        <img :src="collegeInfo.logo" alt="" />
      </div>
      <div class="text">
        <div class="name">
          {{ collegeInfo.organizationName }}
          <div v-if="collegeInfo.verifyStatus == 0" class="item-rz">
            <img src="../../../assets/common/rz-norz.png" class="item-images" />
            <span class="item-rz-one">未认证</span>
            <span
              v-if="superAdminDetail.admin == true || superAdminDetail.superAdmin == true"
              class="item-rz-ht"
              @click="toCollegeSubject"
              >去认证</span
            >
          </div>
          <div v-if="collegeInfo.verifyStatus == 1" class="item-rz">
            <img src="../../../assets/common/rz-center.png" class="item-image" />
            <span class="item-rz-one">审核中</span>
          </div>
          <div v-if="collegeInfo.verifyStatus == 2" class="item-rz">
            <img src="../../../assets/common/rz-over.png" class="item-images" />
            <span class="item-rz-two">已认证</span>
          </div>
          <div v-if="collegeInfo.verifyStatus == 3" class="item-rz">
            <img src="../../../assets/common/rz-nopass.png" class="item-image" />
            <span class="item-rz-thr">认证失败</span>
            <span
              v-if="superAdminDetail.admin == true || superAdminDetail.superAdmin == true"
              class="item-rz-ht"
              @click="toCollegeSubject"
              >去认证</span
            >
          </div>
        </div>
        <div class="intro">
          {{ collegeInfo.introduction }}
        </div>
      </div>
    </div>
    <!-- <ul class="data">
      <li v-for="item in dataSource" :key="item.name">
        <div>
          <img :src="item.icon" alt="" />
          <p>{{ item.name }}</p>
        </div>
        <countTo :start-val="0" :end-val="item.num" :duration="countDuration"></countTo>
      </li>
    </ul> -->
    <!-- <div class="help" @click="helpManual">帮助手册</div> -->
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('user')
// import countTo from 'vue-count-to'
export default {
  name: 'DashboardInfoCard',
  // components: { countTo },
  props: {
    superAdminDetail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      countDuration: 1600,
      dataSource: [
        { name: '书院总人数', num: 1556, icon: require('@/assets/common/home_people_icon.png') },
        { name: '在线人数', num: 456, icon: require('@/assets/common/home_online_icon.png') },
        { name: '完成培训班级数', num: 18, icon: require('@/assets/common/home_class_icon.png') },
        { name: '正在培训班级数', num: 88, icon: require('@/assets/common/home_studing_icon.png') },
        { name: '总完成学时', num: 384223, icon: require('@/assets/common/home_time_icon.png') },
      ],
    }
  },
  computed: {
    ...mapState(['collegeInfo']),
  },
  methods: {
    async helpManual() {
      let src =
        'https://statics-saas.i-ronge.com/frontend/help-book/%E8%9E%8De%E5%AD%A6%C2%B7%E6%98%8E%E5%95%86%E9%81%93%E6%93%8D%E4%BD%9C%E6%89%8B%E5%86%8C%EF%BC%88%E4%BC%81%E4%B8%9A%EF%BC%89.pdf'
      const response = await fetch(src)
      const blob = await response.blob()
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = '帮助手册'
      a.click()
      window.URL.revokeObjectURL(url)
    },
    toCollegeSubject() {
      this.$router.push('/college/collegeSetting')
    },
  },
}
</script>
<style lang="scss" scoped>
.card {
  &.info {
    width: 100%;
    padding: 20px;
    .info {
      display: flex;
      margin-bottom: 20px;
      .logo {
        width: 120px;
        height: 120px;
        background: #ffffff;
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
        img {
          @extend %width-height;
        }
      }
      .text {
        flex: 1;
        padding-left: 18px;
        .name {
          font-size: 18px;
          font-weight: 500;
          color: #333333;
          padding-top: 10px;
          margin-bottom: 6px;
          display: flex;
          .item-rz {
            display: flex;
            margin-left: 20px;
            img {
              margin-top: 5px;
              margin-right: 5px;
            }
            .item-images {
              width: 14px;
              height: 17px;
            }
            .item-image {
              width: 17px;
              height: 17px;
            }
            span {
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              padding-top: 3px;
            }
            .item-rz-one {
              color: #b1b1b1;
            }
            .item-rz-two {
              color: #fdab34;
            }
            .item-rz-thr {
              color: #ff4d4f;
            }
            .item-rz-ht {
              margin-left: 20px;
              color: #ff7b33;
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
        .intro {
          width: 630px;
          font-size: 14px;
          font-weight: 400;
          color: #606266;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }
    .data {
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 0 158px;
      li {
        text-align: center;
        > div {
          display: flex;
          p {
            font-size: 14px;
            color: #666666;
            padding-top: 2px;
          }
          img {
            width: 28px;
            height: 28px;
            margin-right: 8px;
          }
        }
        > span {
          font-size: 30px;
          color: #333333;
          font-weight: 600;
        }
      }
    }
    .help {
      margin-left: 138px;
      cursor: pointer;
      width: 128px;
      line-height: 36px;
      background: #f2f2f2;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 400;
      color: #ff7b33;
      text-align: center;
      margin-top: 12px;
    }
  }
}
</style>
