import { render, staticRenderFns } from "./ClassCard.vue?vue&type=template&id=40c13104&scoped=true&"
var script = {}
import style0 from "./ClassCard.vue?vue&type=style&index=0&id=40c13104&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40c13104",
  null
  
)

export default component.exports