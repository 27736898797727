<!--
 * @Description: 首页
 * @Author: xiawenlong
 * @Date: 2021-04-20 10:47:44
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-04-23 11:53:27
-->
<template>
  <div class="dashboard">
    <InfoCard :super-admin-detail="superAdminDetail" />
    <EnterCard :menu="menu" :super-admin-detail="superAdminDetail" />
    <NewsCard :college-news="collegeNews" />
    <Increment />
    <!-- <StudyCard />
    <TodoCard />
    <ClassCard />
    <TimeCard /> -->
  </div>
</template>
<script>
import { getCollegeNews } from '@/api/dashboard'
import to from 'await-to'
import {
  InfoCard,
  EnterCard,
  NewsCard,
  Increment,
  // StudyCard,
  // TodoCard,
  // ClassCard,
  // TimeCard,
} from './components'
import * as echarts from 'echarts/core'
import { GridComponent } from 'echarts/components'
import { BarChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'
import { getMenu, searchSuperAdmin } from '@/api/college'
echarts.use([GridComponent, BarChart, CanvasRenderer])
export default {
  components: {
    InfoCard,
    EnterCard,
    NewsCard,
    Increment,
    // StudyCard,
    // TodoCard,
    // ClassCard,
    // TimeCard,
  },
  data() {
    return {
      collegeNews: [],
      pager: {
        total: 0,
        size: 4,
        current: 1,
      },
      menu: [],
      clientCode: '260bb55c-47e3-48d0-bd8c-c2cf1ce5',
      superAdminDetail: {},
    }
  },
  created() {
    this.getCollegeNewsData()
    this.getMenuData()
    this.searchSuperAdminData()
  },
  mounted() {
    // var chartStudyDom = document.getElementById('chartStudy')
    // var chartClassDom = document.getElementById('chartClass')
    // var chartStudy = echarts.init(chartStudyDom)
    // var chartClass = echarts.init(chartClassDom)
    // chartStudy.setOption({
    //   color: ['#85BEFD'],
    //   xAxis: {
    //     type: 'category',
    //     data: [
    //       '10月',
    //       '11月',
    //       '12月',
    //       '1月',
    //       '2月',
    //       '3月',
    //       '4月',
    //       '5月',
    //       '6月',
    //       '7月',
    //       '8月',
    //       '9月',
    //     ],
    //   },
    //   yAxis: {
    //     type: 'value',
    //   },
    //   series: [
    //     {
    //       data: [120, 200, 150, 80, 70, 110, 130, 110, 130, 110, 130, 130],
    //       type: 'bar',
    //     },
    //   ],
    // })
    // chartClass.setOption({
    //   color: ['#4BC2B9'],
    //   xAxis: {
    //     type: 'category',
    //     data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    //   },
    //   yAxis: {
    //     type: 'value',
    //   },
    //   series: [
    //     {
    //       data: [120, 200, 150, 80, 70, 110, 130],
    //       type: 'bar',
    //     },
    //   ],
    // })
  },
  methods: {
    async getCollegeNewsData() {
      const [res, err] = await to(getCollegeNews({ ...this.pager }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.collegeNews = res.data.list
    },
    async getMenuData() {
      const [res, err] = await to(getMenu({ clientCode: this.clientCode }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.menu = res.data
    },
    async searchSuperAdminData() {
      const [res, err] = await to(searchSuperAdmin())
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.superAdminDetail = res.data
    },
  },
}
</script>
<style lang="scss" scoped>
.dashboard {
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  ::v-deep.card {
    background-color: #fff;
    margin-bottom: 20px;
    .header {
      position: relative;
      height: 54px;
      display: flex;
      align-items: center;
      padding: 0 20px;
      border-bottom: 1px solid #e2e2e2;
      .title {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 18px;
        font-weight: 500;
        color: #333333;
        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 12px;
          width: 4px;
          height: 32px;
          background: #ff7b33;
        }
        span {
          cursor: pointer;
          font-size: 14px;
          font-weight: 500;
          color: #ff7b33;
        }
      }
    }
  }
}
</style>
