<!--
 * @Description: 书院动态
 * @Author: xiawenlong
 * @Date: 2021-04-22 19:41:52
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-04-23 11:54:30
-->
<template>
  <div class="card news">
    <div class="header">
      <div class="title">书院动态 <span @click="goAllNews">全部动态</span></div>
    </div>
    <div class="content">
      <el-timeline>
        <el-timeline-item
          v-for="(activity, index) in collegeNews"
          :key="index"
          placement="top"
          :timestamp="activity.createTime"
        >
          <div class="contentText">{{ activity.content }}</div>
        </el-timeline-item>
      </el-timeline>
    </div>
  </div>
</template>
<script>
export default {
  name: 'NewsCard',
  props: {
    collegeNews: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {}
  },
  methods: {
    goAllNews() {
      this.$router.push('/dashbord/allNews')
    },
  },
}
</script>
<style lang="scss" scoped>
.card {
  &.news {
    width: 363px;
    // height: 285px;
    margin-left: 20px;
    .content {
      overflow: hidden;
      overflow-y: auto;
      padding: 10px 0 0 26px;
      height: 235px;
      box-sizing: border-box;
      .contentText {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
      ::v-deep.el-timeline-item {
        cursor: pointer;
        padding-bottom: 11px;
        font-size: 14px;
        &:hover {
          .el-timeline-item__node {
            transform: scale(1.2);
          }
        }
      }
      ::v-deep.el-timeline-item__timestamp {
        margin-bottom: 2px;
      }
      ::v-deep.el-timeline-item__node {
        z-index: 60;
        width: 8px;
        height: 8px;
        background: #ffd0b7;
        border: 1px solid #ff7b33;
        border-radius: 50%;
        transition: all 0.3s;
      }
      ::v-deep.el-timeline-item__tail {
        left: 3px;
        border-color: #e2e2e2;
      }
    }
  }
}
</style>
