<!--
 * @Description: 平均学习时长统计
 * @Author: xiawenlong
 * @Date: 2021-04-22 19:51:10
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-04-22 20:27:58
-->
<template>
  <div class="card time">
    <div class="header">
      <div class="title">平均学习时长统计</div>
    </div>
    <div class="content">
      <div class="datas">
        <p>学员总人数：1556人</p>
        <p>最高学习时长：40小时</p>
        <p>最低学习时长：10小时</p>
      </div>
      <el-progress
        :format="format"
        color="#FF7B33"
        :stroke-width="8"
        type="circle"
        :percentage="25"
      ></el-progress>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TimeCard',
  methods: {
    format() {
      return 15
    },
  },
}
</script>
<style lang="scss" scoped>
.card {
  &.time {
    width: 363px;
    height: 307px;
    margin-left: 20px;
    .content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 40px 46px 0 18px;
    }
    .datas {
      padding-top: 23px;
      p {
        position: relative;
        font-size: 14px;
        color: #333333;
        padding-left: 14px;
        margin-bottom: 14px;
        &:nth-of-type(2) {
          &::before {
            background: #36b27d;
          }
        }
        &:nth-of-type(3) {
          &::before {
            background: #ff6d4b;
          }
        }
        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          margin-top: -4px;
          width: 8px;
          height: 8px;
          background: #ffac00;
          border-radius: 50%;
        }
      }
    }
    ::v-deep.el-progress-circle {
      width: 115px !important;
      height: 115px !important;
      &::after {
        padding-top: 10px;
        display: block;
        width: 115px;
        text-align: center;
        content: '平均学习时长';
        font-size: 12px;
        color: #666666;
      }
    }
    ::v-deep.el-progress__text {
      width: 80px;
      height: 80px;
      line-height: 80px;
      background: #ffffff;
      border-radius: 50%;
      box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
      font-size: 24px !important;
      color: #666666;
      left: 50%;
      margin-left: -40px;
      &::after {
        content: '小时';
        font-size: 12px;
        color: #666666;
      }
    }
  }
}
</style>
